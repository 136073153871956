import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { withAppContext } from "../../contextApi/Auth";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import boxShadows from "../../assets/theme/box-shadow.js";
import axios from "axios";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/loader.js";

const useStyles = makeStyles(componentStyles);

function CreateSize({
  createSize,
  open,
  handleClose,
  updateSize,
  setUpdateData,
  UpdateData,
  addSizeLoading,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [Name, setName] = useState("");

  const clearState = () => {
    setName("");
    setUpdateData("");
  };
  const capitalizeText = (text) => {
    const firstLetter = text;
    const remainLetters = text.slice(1).toLowerCase();
    const first = firstLetter.charAt(0).toUpperCase();
    return first + remainLetters;
  };

  const handleCreateSubmit = (e) => {
    if (Name?.length) {
      const Obj = {
        size: capitalizeText(Name),
      };
      if (UpdateData) {
        updateSize(Obj, handleClose, clearState, UpdateData.id);
      } else {
        createSize(Obj, handleClose, clearState);
        toast.success(`Successfully added Size`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    } else {
      toast.error(`please fill name field`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  };
  const handleSize = (e) => {
    if (Name === "" && e.key === "Enter") {
      toast.error(`please enter a size in the field`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      if (e.key === "Enter") {
        handleCreateSubmit();
      }
    }
  };

  useEffect(() => {
    setName(UpdateData.size);
  }, [UpdateData]);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.getElementById("size-input").focus();
      }, 300);
    }
  }, [open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Color
        </DialogTitle>
        <DialogContent>
          <Card classes={{ root: classes.cardRoot }}>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd4 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    id="size-input"
                    autoComplete="off"
                    type="text"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyPress={handleSize}
                  />
                </FormControl>
              </FormGroup>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => handleCreateSubmit()}
            disabled={addSizeLoading}
          >
            {addSizeLoading ? (
              <Loader cumtomStyle={{ position: "unset" }} />
            ) : UpdateData ? (
              "Update Size"
            ) : (
              "Create Size"
            )}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAppContext(CreateSize);
