import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";
import { io } from "socket.io-client";

const useStyles = makeStyles(componentStyles);

const SaleTable = ({ refresh }) => {
  const classes = useStyles();
  const history = useHistory();
  const [AllSale, setAllSale] = useState([]);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState("");
  const socket = io(`${BACKEND_URL}`);
  useEffect(() => {
    console.log("RESCheck");
    socket.on("new_Order", (res) => {
      console.log(res, "RES");
      GetAllSale();
      // setAllSale([...AllSale, res]);
    });
  }, []);
  function GetAllSale() {
    axios
      .get(`${BACKEND_URL}/GetAllSales`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        console.log(res.data, "res.data");
        setAllSale(res.data);
        setData(res.data);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    GetAllSale();
  }, []);

  function TrackOrder() {
    AllSale?.map((a) => {
      if (a.TrackingNumber) {
        const config = {
          method: "get",
          url: `https://sonic.pk/api/shipment/track?tracking_number=${Number(
            a.TrackingNumber
          )}&type=${1}`,
          headers: {
            Authorization:
              "U2tQUGpzSnpLV3hhREtSY3VQaHRTdmJXdWNvVHEyNUtGNUVCang2akE1NUxBMWZlRVI3cXVta041SDBV5bd17829af7f2",
          },
        };

        axios(config)
          .then(function (response) {
            a.TraxInfo = response.data.details?.tracking_history?.[0]?.status;
            setAllSale(AllSale);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  useEffect(() => {
    TrackOrder();
  }, []);
  const handleChange = (event) => {
    setSort(event.target.value);
    if (event.target.value === "confirmed") {
      let arr = [...data];
      let filter = arr?.filter((ev) => ev?.isConfirmed === 1);
      setAllSale(filter);
    } else if (event.target.value === "cancelled") {
      let arr = [...data];
      let filter = arr?.filter((ev) => ev?.isCanceled === 1);
      setAllSale(filter);
    } else {
      let arr = [...data];
      setAllSale(arr);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Sale"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "11px 30px",
            }}
          >
            <select
              style={{
                padding: "6px",
                fontSize: "14px",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sort}
              label="sort"
              onChange={handleChange}
            >
              <option value={"all"}>All</option>
              <option value={"confirmed"}>Confirmed</option>
              <option value={"cancelled"}>Cancelled</option>
            </select>
          </div>
          {AllSale?.length >= 1 ? (
            <TableContainer>
              <Box
                component={Table}
                alignItems="center"
                marginBottom="0!important"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      TrackingNumber
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Order Number
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Total
                    </TableCell>
                    {/* <TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Location
										</TableCell> */}

                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Customer
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Guest
                    </TableCell>
                    {/* <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Delivered
                    </TableCell>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Received
                    </TableCell> */}
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Confirmed / Cancelled
                    </TableCell>
                    {/* <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Cancelled
                    </TableCell> */}
                    {/* <TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											TraxInfo
										</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AllSale?.map((Sale) => {
                    let date = new Date(Sale?.createdAt);
                    return (
                      <TableRow
                        key={Sale?.id}
                        style={{
                          // backgroundColor: Sale?.isCanceled ? "red" : "",
                          // color: Sale?.isCanceled ? "#fff" : "",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(`DetailSale?id=${Sale?.id}`)
                        }
                      >
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {date?.toDateString()} {date?.toLocaleTimeString()}
                        </TableCell>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                          component="th"
                          variant="head"
                          scope="row"
                        >
                          <Box alignItems="center" display="flex">
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize=".875rem" component="span">
                                {Sale?.TrackingNumber || "-"}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                          component="th"
                          variant="head"
                          scope="row"
                        >
                          <Box alignItems="center" display="flex">
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize=".875rem" component="span">
                                {Sale?.id || "-"}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.total || 0}
                        </TableCell>
                        {/* <TableCell classes={{ root: classes.tableCellRoot }}>
													{Sale?.Location || "-"}
												</TableCell> */}
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.customerid?.name || "-"}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.guestid?.name || "-"}
                        </TableCell>
                        {/* <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.isDelivered || "false"}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.isRecived || "false"}
                        </TableCell> */}
                        <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {!Sale?.isConfirmed && !Sale?.isCanceled ? (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 1024 1024"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"></path>
                            </svg>
                          ) : Sale?.isConfirmed ? (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"></path>
                            </svg>
                          ) : Sale?.isCanceled ? (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 512 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path>
                            </svg>
                          ) : null}
                        </TableCell>
                        {/* <TableCell
                          classes={{ root: classes.tableCellRoot }}
                          style={
                            {
                              // color: Sale?.isCanceled ? "#fff" : "",
                            }
                          }
                        >
                          {Sale?.isCanceled ? (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"></path>
                            </svg>
                          ) : (
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 512 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path>
                            </svg>
                          )}
                        </TableCell> */}
                        {/* <TableCell classes={{ root: classes.tableCellRoot }}>
													{Sale?.TraxInfo || "-"}
												</TableCell> */}

                        {/* <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box display="flex" alignItems="center">
                            <Box
                              component="span"
                              marginRight=".5rem"
                              onClick={() =>
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                  preConfirm: () => {
                                    axios
                                      .post(
                                        `${BACKEND_URL}/DeleteSale/${Sale?.id}`,
                                        {},
                                        {
                                          headers: {
                                            token:
                                              localStorage.getItem("Token"),
                                          },
                                        }
                                      )
                                      .then(() => {
                                        GetAllSale();
                                      })
                                      .catch((res) => console.log(res));
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    Swal.fire(
                                      "Deleted!",
                                      "Your Product has been deleted.",
                                      "success"
                                    );
                                  }
                                })
                              }
                            >
                              <Delete
                                style={{
                                  color: "red",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </Box>
                          </Box>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Box>
            </TableContainer>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>No Sale to Show</h1>
            </div>
          )}
          {/* <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination count={3} color="primary" variant="outlined" />
          </Box> */}
        </Card>
      </Container>
    </>
  );
};

export default withAppContext(SaleTable);
