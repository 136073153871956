import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { withAppContext } from "../../contextApi/Auth";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import boxShadows from "../../assets/theme/box-shadow.js";
import axios from "axios";
import { BACKEND_URL } from "../../env";
import Loader from "../../components/Loader/loader";
import { toast } from "react-toastify";

const useStyles = makeStyles(componentStyles);

function CreateUpdateSubCategory({
  open,
  handleClose,
  UpdateId,
  GetAllCategories,
  CategoryId,
  setCategoryId,
  AllCategory,
}) {
  var regexPattern = /^[a-zA-Z0-9\s]*$/;

  const classes = useStyles();
  const theme = useTheme();
  const [Loading, setLoading] = useState(false);

  function GetCategoryInfo() {
    axios
      .get(`${BACKEND_URL}/GetSubCategoryInfo/${UpdateId}`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        setName(res.data[0].name);
      })
      .catch((res) => console.log(res));
  }

  useEffect(() => {
    GetCategoryInfo();
  }, [UpdateId]);

  const [Name, setName] = useState("");

  const handleCreateSubmit = (e) => {
    let filter = AllCategory.filter((c) => c.id === CategoryId);
    // categoryName: filter[0]?.name,
    const Obj = {
      name: Name,
      Category: CategoryId,
    };
    if (Obj?.name?.length) {
      setLoading(true);
      const config = {
        method: "post",
        url: UpdateId
          ? `${BACKEND_URL}/UpdateSubCategory/${UpdateId}`
          : `${BACKEND_URL}/CreateSubCategory`,
        data: Obj,
        headers: {
          token: localStorage.getItem("Token"),
        },
      };
      axios(config)
        .then((res) => {
          GetAllCategories();
          setName(" ");
          handleClose();
          setLoading(false);
          toast.success(`Successfully added ${Obj?.name} subcategory`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
          toast.error(error.response?.data?.message || `Invalid error`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });
    } else {
      toast.error("Please Fill The subcategory field", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  };
  const handleSaver = (e) => {
    if (e.key === "Enter") {
      handleCreateSubmit();
    }
  };
  const enterLogin = (e) => {
    console.log(e);
    if (e?.keyCode === 13) {
      handleCreateSubmit();
    }
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.getElementById("subCategory-input").focus();
      }, 300);
    }
  }, [open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setName("");
          handleClose();
        }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Category
        </DialogTitle>
        <DialogContent>
          <Card classes={{ root: classes.cardRoot }}>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd4 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    id="subCategory-input"
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                    value={Name}
                    onChange={(e) => {
                      if (regexPattern.test(e.target.value)) {
                        console.log("Input is valid.");
                        setName(e.target.value);
                        // setName(e.target.value)
                        // Continue with your logic for valid input
                      } else {
                        console.log(
                          "Input is invalid. Only letters, digits, and spaces are allowed."
                        );
                        toast.error(
                          "Input is invalid. Only letters, digits, and spaces are allowed."
                        );
                        // Display an error message or take appropriate action for invalid input
                      }
                    }}
                    onKeyPress={handleSaver}
                  />
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Category</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={Select}
                    value={CategoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                    onKeyDown={(e) => enterLogin(e)}
                  >
                    {AllCategory.map((a) => (
                      <MenuItem value={a?.id} key={a?.id}>
                        {a?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </FormControl>
              </FormGroup>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button disabled={Loading} onClick={() => handleCreateSubmit()}>
            {Loading ? (
              <Loader cumtomStyle={{ position: "unset" }} />
            ) : UpdateId ? (
              "Update SubCategory"
            ) : (
              "Create SubCategory"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAppContext(CreateUpdateSubCategory);
