import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { withAppContext } from "../../contextApi/Auth";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import boxShadows from "../../assets/theme/box-shadow.js";
import axios from "axios";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/loader";

const useStyles = makeStyles(componentStyles);
let initailValue = {
  quantity: 0,
  price: 0,
};
function CreateUpdateShippingCharges({
  open,
  handleClose,
  UpdateId,
  GetAllShippingCharges,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [Loading, setLoading] = useState(false);
  const [object, setObject] = useState(initailValue);
  const [pieces, setPieces] = useState("1 Piece");
  function GetCategoryInfo() {
    axios
      .get(`${BACKEND_URL}/GetShippingCharges/${UpdateId}`)
      .then((res) => {
        // setName(res.data[0].name);
        setObject({ price: res.data[0].price, quantity: res.data[0].quantity });
      })
      .catch((res) => console.log(res));
  }

  useEffect(() => {
    if (UpdateId) {
      GetCategoryInfo();
    }
  }, [UpdateId]);

  const handleCreateSubmit = (e) => {
    const Obj = {
      quantity: Number(object?.quantity),
      price: Number(object?.price),
      piece: pieces,
    };
    if (Obj?.quantity !== 0 && Obj?.price !== 0) {
      setLoading(true);
      console.log(Obj);
      const config = {
        method: "post",
        url: UpdateId
          ? `${BACKEND_URL}/UpdateShippingCharges/${UpdateId}`
          : `${BACKEND_URL}/CreateShippingCharges`,
        data: Obj,
        headers: {
          token: localStorage.getItem("Token"),
        },
      };
      axios(config)
        .then((res) => {
          GetAllShippingCharges();
          handleClose();
          //   setName(" ");
          setLoading(false);
          setObject(initailValue);
          console.log(res);
          toast.success(`Successfully added Shipping Charges`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        })
        .catch((res) => {
          setLoading(false);
          toast.error(`Invalid error`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });
    } else {
      toast.error("Please Fill This category field", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  };
  const handleSave = (e) => {
    if (e.key === "Enter") {
      handleCreateSubmit();
    }
  }
  const enterLogin = (e) => {
    console.log(e);
    if (e?.keyCode === 13) {
      handleCreateSubmit();
    }
  };
  const handleChange = (e) => {
    setObject({ ...object, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.getElementById("quantity-input").focus();
      }, 300);
    }
  }, [open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Shipping Charges
        </DialogTitle>
        <DialogContent>
          <Card classes={{ root: classes.cardRoot }}>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd4 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Quantity</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    id="quantity-input"
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                    name="quantity"
                    value={object?.quantity}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={handleSave}
                    // onKeyDown={(e) => enterLogin(e)}
                  />
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Piece</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={Select}
                    autoComplete="off"
                    type="text"
                    value={pieces}
                    onChange={(e) => setPieces(e.target.value)}
                  >
                    <MenuItem value={"1 Piece"}>1 Piece</MenuItem>
                    <MenuItem value={"2 Piece"}>2 Piece</MenuItem>
                    <MenuItem value={"3 Piece"}>3 Piece </MenuItem>
                  </Box>
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Price</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem !important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="number"
                    name="price"
                    value={object?.price}
                    onChange={(e) => handleChange(e)}
                    // onKeyDown={(e) => enterLogin(e)}
                  />
                </FormControl>
              </FormGroup>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button onClick={() => handleCreateSubmit()}>
            {Loading ? (
              <Loader cumtomStyle={{ position: "unset" }} />
            ) : UpdateId ? (
              "Update Category"
            ) : (
              "Create Category"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAppContext(CreateUpdateShippingCharges);
