import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { withAppContext } from "../../contextApi/Auth";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import boxShadows from "../../assets/theme/box-shadow.js";
import axios from "axios";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/loader.js";

const useStyles = makeStyles(componentStyles);

function CreateColor({
  createColor,
  open,
  handleClose,
  UpdateData,
  setUpdateData,
  updateColor,
  addColorLoading,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [Name, setName] = useState("");

  const clearState = () => {
    setName("");
    setUpdateData("");
  };

  const capitalizeText = (text) => {
    const firstLetter = text;
    const remainLetters = text.slice(1).toLowerCase();
    const first = firstLetter.charAt(0).toUpperCase();
    return first + remainLetters;
  };

  const handleColorAction = () => {
    if (Name?.length) {
      const Obj = {
        color: capitalizeText(Name),
      };
      if (UpdateData) {
        updateColor(Obj, handleClose, clearState, UpdateData.id);
      } else {
        createColor(Obj, handleClose, clearState);
      }
    } else {
      toast.error(`please fill name field`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  };
  const handleCreateSubmit = (e) => {
    if (e.key === "Enter") {
      handleColorAction();
      // toast.success(`Successfully added color`, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      // });
    }
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.getElementById("color-input").focus();
      }, 300);
    }
  }, [open]);
  useEffect(() => {
    setName(UpdateData.color);
  }, [UpdateData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Color
        </DialogTitle>
        <DialogContent>
          <Card classes={{ root: classes.cardRoot }}>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd4 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    id="color-input"
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyPress={handleCreateSubmit}
                  />
                </FormControl>
              </FormGroup>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button disabled={addColorLoading} onClick={handleColorAction}>
            {addColorLoading ? (
              <Loader cumtomStyle={{ position: "unset" }} />
            ) : UpdateData ? (
              "Update Color"
            ) : (
              "Create Color"
            )}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAppContext(CreateColor);
