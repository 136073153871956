import React, { useEffect, useState, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components

import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import CreateColor from "./CreateColor";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import { BACKEND_URL } from "../../env.js";

// import SearchIcon from "@mui/icons-material/Search";
// import InputBase from "@mui/material/InputBase";
// import { styled, alpha } from "@mui/material/styles";

const useStyles = makeStyles(componentStyles);

const Color = ({ getColor, deleteColor, ...props }) => {
  const classes = useStyles();

  const [openModel, setOpenModel] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const [colors, setColors] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const searchInputRef = useRef(null);

  const handleClickOpen = () => {
    setOpenModel(true);
  };

  const handleClose = () => {
    setUpdateData("");
    setOpenModel(false);
  };

  useEffect(() => {
    if (props.colorData) {
      setColors(props.colorData);
      //   setFilteredResults(props.colorData);
    }
    console.log(props.colorData);
  }, [props.colorData]);

  useEffect(() => {
    getColor();
  }, []);

  console.log("adsadsadasd ");

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filterResults = () => {
    if (colors && colors.length > 0) {
      const sortedResults = [...colors].sort((a, b) =>
        a?.name?.localeCompare(b.name)
      );
      const filtered = sortedResults.filter((result) =>
        result.name?.toLowerCase().includes(searchInput?.toLowerCase())
      );
      setFilteredResults(filtered);
    }
  };

  const handleSearchClick = () => {
    if (searchInputRef.current.value) {
      const searchText = searchInputRef.current.value.trim();
      let text = searchText.charAt(0).toUpperCase() + searchText.slice(1);
      let arr = [...colors];
      setColors(arr.filter((a) => a.color.includes(text)));
    } else {
      setColors(props.colorData);
    }
  };

  const handleSave = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  useEffect(() => {
    if (searchInputRef) {
      setSearchInput(searchInputRef.current.value);
    }
  }, [searchInputRef]);

  const handleClearClick = () => {
    setColors(props.colorData);
    setSearchInput("");
  };

  //   useEffect(() => {
  //     filterResults(); // Trigger filtering when searchInput changes
  //   }, [searchInput]);

  //   useEffect(() => {
  //     if (colors) {
  //       console.log(colors);
  //       filterResults();
  //     }
  //   }, [colors]);

  // useEffect(() => {
  // 	GetAllCategories();
  // }, []);

  // const Search = styled("div")(({ theme }) => ({
  //   position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  //   marginRight: theme.spacing(2),
  //   marginLeft: 0,
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(3),
  //     width: "auto",
  //   },
  // }));

  // const SearchIconWrapper = styled("div")(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  //   height: "100%",
  //   position: "absolute",
  //   pointerEvents: "none",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   color: "inherit",
  //   "& .MuiInputBase-input": {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create("width"),
  //     width: "100%",
  //     [theme.breakpoints.up("md")]: {
  //       width: "20ch",
  //     },
  //   },
  // }));

  //   const [searchInput, setSearchInput] = useState("");
  //   const [filteredResult, setFilteredResult] = useState([]);

  //   const handleSearchChange = (event) => {
  //     setSearchInput(event.target.value);
  //   };

  //   console.log(setSearchInput.value)

  //   const allResults = [];

  //   const filterResults = () => {
  //     const sortResult = allResults.sort((a, b) => a.name.localeCompare(b.name));
  //     const filtered = sortResult.filter((result) =>
  //       result.name.toLowerCase().includes(searchInput.toLowerCase())
  //     );
  //     setFilteredResult(filtered);
  //   };
  //   const handleKeyPress = (event) => {
  //     if (event.key === "enter") {
  //       filterResults();
  //       console.log("enter ho raha h", filterResults());
  //     }
  //   };

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Available Color"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <>
                <div
                  className="flexing-between"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Box>
                    {/* <Search> */}
                    {/* <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper> */}
                    <input
                      ref={searchInputRef}
                      style={{
                        border: "1px solid #172b4d",
                        borderRadius: ".375rem",
                        height: "41px",
                        width: "189px",
                        padding: "0px 6px",
                      }}
                      placeholder=" 🔍 Search color…"
                      inputProps={{ "aria-label": "search" }}
                      onKeyPress={handleSave}
                      //   value={searchInput}
                      //   onChange={handleSearchChange}
                      // onKeyPress={handleKeyPress}
                    />

                    <Button
                      variant="outlined"
                      style={{ margin: "7px" }}
                      onClick={handleSearchClick}
                    >
                      Search
                    </Button>
                    {/* <Button variant="outlined" onClick={handleClearClick}>
                      Clear
                    </Button> */}
                    {/* </Search> */}
                  </Box>
                  {/* <div className="ResutsOfSearch">
                      <ul>
                        {filteredResult.map((result) => (
                          <li key={result.id}>{result.name}</li>
                        ))}
                      </ul>
                    </div> */}

                  <Button variant="outlined" onClick={handleClickOpen}>
                    Create Color
                  </Button>
                </div>
              </>
            }
          ></CardHeader>

          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Color
                  </TableCell>

                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {colors?.map((a, i) => {
                  return (
                    <TableRow key={a.id}>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        <Box alignItems="center" display="flex">
                          <Box display="flex" alignItems="flex-start">
                            <Box fontSize=".875rem" component="span">
                              {i + 1}
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                          {a?.color}
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box display="flex" alignItems="center">
                          <Box
                            component="span"
                            marginRight=".5rem"
                            onClick={() => {
                              setOpenModel(true);
                              setUpdateData(a);
                            }}
                          >
                            <Edit style={{ width: "20px", height: "20px" }} />
                          </Box>
                          <Box
                            component="span"
                            marginRight=".5rem"
                            onClick={() =>
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                                preConfirm: () => {
                                  deleteColor(a.id);
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  Swal.fire(
                                    "Deleted!",
                                    "Your Category has been deleted.",
                                    "success"
                                  );
                                }
                              })
                            }
                          >
                            <Delete
                              style={{
                                color: "red",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Box>
          </TableContainer>
          {/* ) : (

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <h1>No Color to Show</h1>
                        </div>
                    )} */}
          {/* <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination count={3} color="primary" variant="outlined" />
                    </Box> */}
        </Card>
        <CreateColor
          open={openModel}
          handleClose={handleClose}
          UpdateData={updateData}
          setUpdateData={setUpdateData}
        />
      </Container>
    </>
  );
};

export default withAppContext(Color);
