import React, { useEffect, useState } from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components

import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { BACKEND_URL } from "../../env.js";
import CreateUpdateShippingCharges from "./CreateUpdateShippingCharges.js";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);
let shippingObject = ["1 Piece", "2 Piece", "3 Piece"];
function TabPanel(props) {
  const { children, value, index, key, ...other } = props;
  console.log(key);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
const ShippingChargesTable = ({
  AllShippingCharges,
  GetAllShippingCharges,
}) => {
  const classes = useStyles();

  const [openModel, setOpenModel] = useState(false);

  const [UpdateId, setUpdateId] = useState("");
  const [shippingChargesData, setShippingChargesData] = useState("");
  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState("1 Piece");

  const handleClickOpen = () => {
    setOpenModel(true);
  };

  const handleClose = () => {
    setUpdateId("");
    setOpenModel(false);
  };

  useEffect(() => {
    if (AllShippingCharges?.length) {
      let filter = AllShippingCharges.filter((ev) => ev?.piece === tabs);
      console.log(filter);
      setShippingChargesData(filter);
    }
  }, [AllShippingCharges, tabs]);
  useEffect(() => {
    GetAllShippingCharges();
  }, []);
  const handleChangeTab = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Shipping Charges"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button variant="outlined" onClick={handleClickOpen}>
                Create Shipping Charges
              </Button>
            }
          ></CardHeader>
          <Grid
            item
            xs={12}
            style={{
              padding: "0px",
            }}
          >
            <Tabs
              className="colorImageTab"
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}
            >
              {shippingObject.map((a, i) => {
                return (
                  <Tab
                    key={a}
                    className="buttonTab"
                    label={a}
                    onClick={() => {
                      setTabs(a);
                      // setSelectedColor(a);
                      // setTabColor(a, TabColor);
                    }}
                  />
                );
              })}
            </Tabs>
            {shippingObject.map((a, i) => (
              <TabPanel key={a} value={value} index={i}>
                {shippingChargesData?.length >= 1 ? (
                  <TableContainer>
                    <Box
                      component={Table}
                      alignItems="center"
                      marginBottom="0!important"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            classes={{
                              root:
                                classes.tableCellRoot +
                                " " +
                                classes.tableCellRootHead,
                            }}
                          >
                            ID
                          </TableCell>
                          <TableCell
                            classes={{
                              root:
                                classes.tableCellRoot +
                                " " +
                                classes.tableCellRootHead,
                            }}
                          >
                            Price
                          </TableCell>
                          <TableCell
                            classes={{
                              root:
                                classes.tableCellRoot +
                                " " +
                                classes.tableCellRootHead,
                            }}
                          >
                            Piece
                          </TableCell>
                          <TableCell
                            classes={{
                              root:
                                classes.tableCellRoot +
                                " " +
                                classes.tableCellRootHead,
                            }}
                          >
                            Quantity
                          </TableCell>
                          {/* <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      Actions
                    </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shippingChargesData?.map((cat, i) => {
                          return (
                            <TableRow key={cat.id}>
                              <TableCell
                                classes={{
                                  root:
                                    classes.tableCellRoot +
                                    " " +
                                    classes.tableCellRootBodyHead,
                                }}
                                component="th"
                                variant="head"
                                scope="row"
                              >
                                <Box alignItems="center" display="flex">
                                  <Box display="flex" alignItems="flex-start">
                                    <Box fontSize=".875rem" component="span">
                                      {i + 1}
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell
                                classes={{ root: classes.tableCellRoot }}
                              >
                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                  {cat?.price}
                                </Box>
                              </TableCell>
                              <TableCell
                                classes={{ root: classes.tableCellRoot }}
                              >
                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                  {cat?.piece}
                                </Box>
                              </TableCell>
                              <TableCell
                                classes={{ root: classes.tableCellRoot }}
                              >
                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                  {cat.quantity}
                                </Box>
                              </TableCell>
                              {/* <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box display="flex" alignItems="center"> */}
                              {/* <Box
                              component="span"
                              marginRight=".5rem"
                              onClick={() => {
                                setOpenModel(true);
                                setUpdateId(cat.id);
                              }}
                            >
                              <Edit style={{ width: "20px", height: "20px" }} />
                            </Box> */}
                              {/* <Box
                              component="span"
                              marginRight=".5rem"
                              onClick={() =>
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                  preConfirm: () => {
                                    axios
                                      .post(
                                        `${BACKEND_URL}/DeleteShippingCharges/${cat?.id}`,
                                        {},
                                        {
                                          headers: {
                                            token:
                                              localStorage.getItem("Token"),
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        console.log(res);
                                        GetAllShippingCharges();
                                      })
                                      .catch((res) => console.log(res));
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    Swal.fire(
                                      "Deleted!",
                                      "Your Category has been deleted.",
                                      "success"
                                    );
                                  }
                                })
                              }
                            >
                              <Delete
                                style={{
                                  color: "red",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </Box> */}
                              {/* </Box>
                        </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Box>
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1>No Shipping Charges to Show</h1>
                  </div>
                )}
              </TabPanel>
            ))}
          </Grid>

          {/* <Box
						classes={{ root: classes.cardActionsRoot }}
						component={CardActions}
						justifyContent="flex-end"
					>
						<Pagination count={3} color="primary" variant="outlined" />
					</Box> */}
        </Card>
        <CreateUpdateShippingCharges
          open={openModel}
          handleClose={handleClose}
          UpdateId={UpdateId}
        />
      </Container>
    </>
  );
};

export default withAppContext(ShippingChargesTable);
