import {
  Box,
  Button,
  Container,
  FilledInput,
  FormLabel,
  MenuItem,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import UserHeader from "../../../components/Headers/UserHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import componentStyles from "../../../assets/theme/views/admin/profile";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import { Radio, Select as AntdSelect } from "antd";
import MyEditor from "../../../components/Editor/index";
import Loader from "../../../components/Loader/loader";

const { Option } = AntdSelect;

function StepOne({
  classes,
  setName,
  Name,
  Price,
  setPrice,
  AllCategory,
  setCategory,
  Category,
  setSubCategory,
  SubCategory,
  AllSubCategoriesByCategory,
  Tags,
  TagsData,
  handleChangeTagsSelect,
  size,
  sizeSelected,
  handleChangeSelect,
  colors,
  colorSelected,
  handleChangeColorSelect,
  productId,
  setDescription,
  Description,
  addProductDetail,
  loading,
  setPieces,
  pieces,
  setWeight,
  weight,
  DescriptionnonEditable,
}) {
  return (
    <Card
      classes={{
        root: classes.cardRoot + " " + classes.cardRootSecondary,
      }}
    >
      <CardHeader
        subheader={
          <Grid
            container
            component={Box}
            alignitem="center"
            justifycontent="space-between"
          >
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
              >
                Enter Product Details
              </Box>
            </Grid>
          </Grid>
        }
        // action={
        //     <Button
        //         variant="contained"
        //         size="small"
        //         classes={{ root: classes.buttonRootDark }}
        //         onClick={() => handleCreateSubmit()}
        //     >
        //         {loading ? <Loader /> : productId ? "Update" : "Create"}
        //     </Button>
        // }
        classes={{ root: classes.cardHeaderRoot }}
      ></CardHeader>
      <CardContent>
        <div className={classes.plLg4}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Price</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="number"
                    value={Price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Category</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={Select}
                    value={Category}
                    required
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {AllCategory?.map((a) => (
                      <MenuItem value={a?.name}>{a?.name}</MenuItem>
                    ))}
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Sub Category</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={Select}
                    autoComplete="off"
                    type="text"
                    value={SubCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    {AllSubCategoriesByCategory &&
                      AllSubCategoriesByCategory?.map((b) => {
                        return <MenuItem value={b?.name}>{b?.name}</MenuItem>;
                      })}
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Tags</FormLabel>
                {/* <FormControl
													variant="filled"
													component={Box}
													width="100%"
													marginBottom="1rem!important"
												> */}
                <AntdSelect
                  mode="tags"
                  placeholder="Please select"
                  onChange={handleChangeTagsSelect}
                  value={Tags}
                  style={{ width: "100%" }}
                >
                  {TagsData?.map((a, i) => {
                    return <Option key={a}>{a}</Option>;
                  })}
                </AntdSelect>

                {/* <Box
														paddingLeft="0.75rem"
														paddingRight="0.75rem"
														component={FilledInput}
														autoComplete="off"
														type="text"
														value={Tags}
														onChange={(e) => setTags(e.target.value)}
													/>
													 */}
                {/* <TagComp setTags={setTags} /> */}
                {/* </FormControl> */}
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Weight</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="number"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    required
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Piece</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={Select}
                    autoComplete="off"
                    type="text"
                    value={pieces}
                    onChange={(e) => setPieces(e.target.value)}
                  >
                    <MenuItem value={"1 Piece"}>1 Piece</MenuItem>
                    <MenuItem value={"2 Piece"}>2 Piece</MenuItem>
                    <MenuItem value={"3 Piece"}>3 Piece </MenuItem>
                  </Box>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Size</FormLabel>
                <AntdSelect
                  mode="multiple"
                  value={sizeSelected}
                  placeholder="Please select"
                  onChange={handleChangeSelect}
                  style={{ width: "100%" }}
                >
                  {size?.map((a, i) => {
                    return <Option key={a}>{a}</Option>;
                  })}
                </AntdSelect>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel>Color</FormLabel>
                <AntdSelect
                  mode="multiple"
                  placeholder="Please select"
                  value={colorSelected}
                  onChange={handleChangeColorSelect}
                  style={{ width: "100%" }}
                >
                  {colors?.map((a, i) => {
                    return <Option key={a}>{a}</Option>;
                  })}
                </AntdSelect>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormLabel>Description</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  {/* {Description !== "" ? ( */}
                  <MyEditor
                    Description={Description}
                    setDescription={setDescription}
                    productId={productId}
                    DescriptionnonEditable={DescriptionnonEditable}
                  />
                  {/* ) : null} */}
                </FormControl>
              </FormGroup>
            </Grid>
            <Button
              style={{
                width: "100%",
                margin: "0 1.5rem",
                padding: "10px 20px",
                marginBottom: "20px",
              }}
              variant="contained"
              size="small"
              className="addVariety"
              classes={{ root: classes.buttonRootDark }}
              onClick={() => addProductDetail()}
            >
              {loading ? (
                <Loader />
              ) : productId ? (
                "Update Product Detail & Next"
              ) : (
                "Add Product Detail & Next"
              )}
            </Button>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}
export default StepOne;
