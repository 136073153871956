import {
  Box,
  Button,
  Container,
  FilledInput,
  FormLabel,
  MenuItem,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import UserHeader from "../../../components/Headers/UserHeader";
import { withAppContext } from "../../../contextApi/Auth";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import componentStyles from "../../../assets/theme/views/admin/profile";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Loader from "../../../components/Loader/loader";
import { useState, useEffect } from "react";
const useStyles = makeStyles(componentStyles);
import { Radio, Select as AntdSelect, Steps } from "antd";
import MyEditor from "../../../components/Editor/index";
import { BACKEND_URL } from "../../../env";
import { toast } from "react-toastify";
import axios from "axios";
import StepOne from "./Stepone";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import "../Drag.css";
import { useHistory } from "react-router-dom";
import ImageCompressor from "image-compressor";
// import { webp } from 'webp-converter';
const { Option } = AntdSelect;
const steps = [
  {
    title: "Add Product Details",
    content: "First-content",
  },
  {
    title: "Add Variant",
    content: "Second-content",
  },
  {
    title: "Add Images",
    content: "Last-content",
  },
];

const fileTypes = ["JPEG", "jpg", "png", "webp", "MP4", "jfif"];

function CreateProduct({
  AllCategory,
  getTags,
  TagsData,
  getSize,
  getColor,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [current, setCurrent] = useState(0);
  const [productId, setProductId] = useState("");
  const [Name, setName] = useState("");
  const [Price, setPrice] = useState(0);
  const [Description, setDescription] = useState("");
  const [DescriptionnonEditable, setDescriptionnonEditable] = useState("");
  const [Category, setCategory] = useState("");
  const [SubCategory, setSubCategory] = useState("");
  const [size, setSize] = useState([]);
  const [sizeSelected, setSizeSelected] = useState([]);
  const [colors, setColors] = useState([]);
  const [colorSelected, setColorSelected] = useState([]);
  const [Tags, setTags] = useState([]);
  const [newProduct, setNewProduct] = useState("");
  const [weight, setWeight] = useState("");
  const [AllSubCategoriesByCategory, setAllSubCategoriesByCategory] = useState(
    []
  );
  const [SelectedColor, setSelectedColor] = useState();
  const [itemsVariety, setItemsVariety] = useState([]);
  const [TabColor, setTabColor] = useState();
  const [value, setValue] = useState(0);
  const [imageUpload, setImageUpload] = useState([]);
  const [pieces, setPieces] = useState("1 Piece");
  const items = steps?.map((item) => ({ key: item.title, title: item.title }));
  const history = useHistory();
  useEffect(() => {
    let url = new URL(window.location);
    let id = url.searchParams.get("id");
    getTags();
    getSize();
    getColor();
    if (id) {
      setProductId(id);
      GetProductInfo(id);
    }
  }, []);
  function GetProductInfo(id) {
    axios
      .get(`${BACKEND_URL}/GetProductInfo/${id}`, {
        headers: {
          token: localStorage.getItem("Token"),
        },
      })
      .then((res) => {
        const arr = [];
        console.log(res.data, "res.data");
        if (res.data) {
          let obj = res.data;
          setName(obj?.name);
          setPrice(obj?.price);
          setDescription(obj?.Description);
          setDescriptionnonEditable(obj?.Description);
          setWeight(obj?.weight);
          setCategory(obj?.Category);
          setPieces(obj?.piece);
          setSubCategory(obj?.SubCategory);
          setTags(obj?.Tags.split(","));
          setColorSelected(obj?.Color.split(","));
          setSelectedColor(obj?.Color.split(",")[0]);
          setSizeSelected(obj?.size.split(","));
          setItemsVariety(obj?.items);
          console.log(obj?.items, "obj?.items");
          updateImageArr(obj?.Images);
        }
      })
      .catch((res) => console.log(res));
  }
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  const updateImageArr = (imagesArr) => {
    let arrayImage = [];
    imagesArr?.map((a, i) => {
      let iamgUrl = "";
      toDataURL(`${BACKEND_URL}/GetImage/${a.filename}`, (url) => {
        iamgUrl = url;
        // arrayImage.push(url);
        arrayImage.push({
          image: url,
          color: a?.itemColor,
          index: a?.indexLevel,
          type: a?.type,
        });

        if (i === imagesArr.length - 1) {
          setImageUpload(arrayImage);
        }
        // })
      });
      // setImageUpload([
      //   ...imageUpload,
      //   {
      //     image: iamgUrl,
      //     color: a?.itemColor,
      //   },
      // ]);
      // let newArr = arrayImage;
      // console.log(arrayImage, "imageUpload");
    });
  };
  useEffect(() => {
    let colorArr = [];
    if (props.colorData) {
      props.colorData.map((a, i) => {
        colorArr.push(a.color);
      });
    }
    // setTabColor(colorArr[0]);
    setColors(colorArr);
  }, [props.colorData]);
  useEffect(() => {
    let sizeArr = [];
    if (props.sizeData) {
      props.sizeData.map((a, i) => {
        sizeArr.push(a.size);
      });
    }
    setSize(sizeArr);
  }, [props.sizeData]);
  const handleChangeSelect = (value) => {
    setSizeSelected([...value]);
  };

  const handleChangeTagsSelect = (value) => {
    setTags([...value]);
  };
  const handleChangeColorSelect = (value) => {
    setColorSelected([...value]);
    setSelectedColor(value[0]);
    console.log(value, "value");
  };
  const addProductDetail = () => {
    if (
      Name.length &&
      Description.length &&
      Category.length &&
      SubCategory.length &&
      Tags.length
    ) {
      setLoading(true);
      const Obj = {
        name: Name,
        price: Price,
        Description: Description,
        Category: Category,
        SubCategory: SubCategory,
        Discount: 0,
        DiscountRs: 0,
        piece: pieces,
        weight: weight,
        Tags: Tags.length ? Tags.join() : "",
        Color: colorSelected.length ? colorSelected.join() : "",
        size: sizeSelected.length ? sizeSelected.join() : "",
      };
      console.log(Obj);
      const config = {
        method: "post",
        url: productId
          ? `${BACKEND_URL}/UpdateProductDetail/${productId}`
          : `${BACKEND_URL}/CreateProductDetail`,
        data: Obj,
        headers: {
          token: localStorage.getItem("Token"),
        },
      };
      axios(config)
        .then((e) => {
          createVariety();
          setName("");
          setPrice("");
          setDescription("");
          setCategory("");
          setSubCategory("");
          setTags([]);
          // setColorSelected([]);
          setSizeSelected([]);
          setDescription("");
          setCurrent(1);
          setNewProduct(e?.data?.product?.id);
          console.log(e?.data);
          toast.success(
            e?.data?.message
              ? e?.data?.message
              : productId
              ? "Product update Successfully"
              : "Product Added Successfully",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log("nnnnn");
          setLoading(false);
          toast.error(
            err?.data?.message ? err?.data?.message : "Invalid Error",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
        });
    } else {
      setLoading(false);
      toast.error(
        `${
          !Name.length
            ? "Please Fill Name Field"
            : // :
            // !Price.length
            // ? "Please Fill Price Field"
            !Description.length
            ? "Please Fill Description Field"
            : !Category.length
            ? "Please Fill Category Field"
            : !SubCategory.length
            ? "Please Fill SubCategory Field"
            : "Invalid"
        }`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        }
      );
    }
  };
  const addProductVarient = () => {
    if (itemsVariety.length) {
      let arr = [];
      itemsVariety?.map((item) => {
        if (item.DiscountRs) {
          arr.push(item);
        } else {
          arr.push({ ...item, DiscountRs: 0 });
        }
      });
      setLoading(true);
      let obj = {
        items: arr,
        productId: productId ? productId : newProduct,
      };
      const config = {
        method: "post",
        url: productId
          ? `${BACKEND_URL}/UpdateProductItems`
          : `${BACKEND_URL}/CreateProductItems`,
        data: obj,
        headers: {
          token: localStorage.getItem("Token"),
        },
      };
      axios(config)
        .then((e) => {
          setCurrent(2);
          toast.success(
            e?.data?.message
              ? e?.data?.message
              : productId
              ? "Product Variant added Successfully"
              : "Product Variant Added Successfully",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log("nnnnn");
          setLoading(false);
          toast.error(
            err?.data?.message ? err?.data?.message : "Invalid Error",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
        });
    } else {
      setLoading(false);
      toast.error(`Invalid Error`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  };
  const addProductImages = () => {
    if (itemsVariety.length) {
      setLoading(true);
      console.log(imageUpload, "imageUpload");
      let obj = {
        Images: imageUpload,
        productId: productId ? productId : newProduct,
        Color: colorSelected.join(),
      };
      const config = {
        method: "post",
        url: productId
          ? `${BACKEND_URL}/UpdateProductImages`
          : `${BACKEND_URL}/CreateItemImages`,
        data: obj,
        headers: {
          token: localStorage.getItem("Token"),
        },
      };
      axios(config)
        .then((e) => {
          setCurrent(2);
          toast.success(
            e?.data?.message
              ? e?.data?.message
              : productId
              ? "Product created Successfully"
              : "Product created Successfully",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
          history.push("/admin/index");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err?.data, "err.data");
          setLoading(false);
          toast.error(
            err?.data?.message ? err?.data?.message : "Invalid Error",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
        });
    } else {
      setLoading(false);
      toast.error(`Invalid Error`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  };
  // console.log(imageUpload);
  // var reader = new FileReader();
  // reader.readAsDataURL(ev);
  // reader.onload = function () {
  //     // if (i === 1) {
  //     //   arrImages.push({
  //     //     image: reader.result,
  //     //     color: color,
  //     //     index: filterImg?.length + 1,
  //     //   });
  //     // } else {
  //     // console.log(reader, "reader");
  //     arrImages.push({
  //         image: reader.result,
  //         type: ev?.type,
  //         color: color,
  //         index: filterImg?.length + i,
  //     });
  //     // }
  //     // setImageUpload([
  //     //   ...imageUpload,

  //     // ]);
  //     if (Object.values(file)?.length === i + 1) {
  //         setImageUpload(arrImages);
  //         // setCropImage(arrImages);
  //         // setCropModal(true);
  //         // console.log(arrImages, "arrImages");
  //     }
  // };
  // reader.onerror = function (error) {
  //     console.log("Error: ", error);
  // };
  // console.log(arrImages, "arrImages");
  // Convert the compressed image to WebP format
  const madeImage = async (file, color, i) => {
    let arrImages = [...imageUpload];
    let filterImg = imageUpload.filter((e) => e?.color === SelectedColor);
    Object.values(file)?.map(async (ev, i) => {
      // console.log(imageUpload);
      var reader = new FileReader();
      reader.readAsDataURL(ev);
      reader.onload = function () {
        // if (i === 1) {
        //   arrImages.push({
        //     image: reader.result,
        //     color: color,
        //     index: filterImg?.length + 1,
        //   });
        // } else {
        // console.log(reader, "reader");
        console.log(Object.values(file)?.length);
        console.log(i);
        // if (ev?.size / 1024 > 1000) {
        arrImages.push({
          image: reader.result,
          type: ev?.type,
          color: color,
          index: filterImg?.length + i,
        });
        // }
        // setImageUpload([
        //       ...imageUpload,
        // ]);
        if (Object.values(file)?.length === i + 1) {
          setImageUpload(arrImages);
          console.log("EEEEEEEEEEEEE");
          // setCropImage(arrImages);
          // setCropModal(true);
          // console.log(arrImages, "arrImages");
        }
        // } else {
        //     toast.error("Image should be less than 1 mb");
        // }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      // const compressedImage = await new ImageCompressor(ev, {
      //     quality: 0.6, // Adjust the compression quality as needed
      // }).compress();
      // console.log(compressedImage, "compressedImage");
      // const inputBuffer = await compressedImage.arrayBuffer();
      // const outputBuffer = webp.encode(inputBuffer, { quality: 80 }); // Adjust the WebP quality as needed

      // // Create a Blob from the WebP data
      // const webpBlob = new Blob([outputBuffer], { type: 'image/webp' });

      // // You can now upload the converted WebP image to a server or perform other operations.
      // // Example: Upload with FormData and axios
      // // const formData = new FormData();
      // // formData.append('image', webpBlob);
      // // axios.post('/upload', formData);

      // console.log('Converted and compressed WebP image:', webpBlob);
      // arrImages.push({
      //     image: webpBlob,
      //     type: ev?.type,
      //     color: color,
      //     index: filterImg?.length + i,
      // });
      // if (Object.values(file)?.length === i + 1) {
      //     setImageUpload(arrImages);
      //     // setCropImage(arrImages);
      //     // setCropModal(true);
      //     // console.log(arrImages, "arrImages");
      // }
    });
  };
  const createVariety = () => {
    if (colorSelected?.length && sizeSelected?.length) {
      let arr = [];
      colorSelected.map((b) => {
        sizeSelected?.map((a) => {
          console.log(a, "sizeSelected");
          console.log(b, "colorSelected");
          if (productId) {
            let data = itemsVariety?.filter(
              (ev) => ev.color === b && ev.size === a
            );
            console.log(data);
            let obj = {
              price: data?.length ? data[0].price : Price,
              itemImg: "",
              color: data?.length ? data[0].color : b,
              size: data?.length ? data[0].size : a,
              Discount: data?.length ? data[0].Discount : 0,
              DiscountRs: data?.length ? data[0].DiscountRs : 0,
              quantity: data?.length ? data[0].quantity : "0",
              QuantityAvailable: data?.length ? data[0].QuantityAvailable : "0",
            };
            arr.push(obj);
          } else {
            let obj = {
              price: Price,
              itemImg: "",
              color: b,
              size: a,
              Discount: 0,
              DiscountRs: 0,
              quantity: "",
              QuantityAvailable: "",
            };
            arr.push(obj);
          }
        });
      });
      // if (productId) {

      // } else {
      setItemsVariety(arr);
      // }
      setTabColor(colorSelected[0]);
    } else if (colorSelected?.length && !sizeSelected?.length) {
      console.log("colorSelected");
      let arr = [];
      colorSelected.map((b) => {
        // sizeSelected?.map((a) => {
        let obj = {
          price: Price,
          itemImg: "",
          color: b,
          size: "",
          Discount: 0,
          quantity: "",
          QuantityAvailable: "",
        };
        arr.push(obj);
        // });
      });
      setTabColor(colorSelected[0]);
      setItemsVariety(arr);
    } else {
      toast.error("Please fill the color field", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
      // setItemsVariety(arr);
    }
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(async () => {
    // console.log("All category------->",AllCategory);

    AllCategory.filter((a) => {
      if (a.name == Category) {
        // console.log("All cat aaa   ------->", a.name, Category);
        setAllSubCategoriesByCategory(a.SubCategory);
      }
    });
  }, [Category]);
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <div
                style={{
                  padding: "21px 20px 20px 21px",
                  background: "#f7fafc",
                  borderRadius: "10px 10px 0px 10px",
                }}
              >
                <Steps current={current} items={items} />
              </div>
              {current === 0 ? (
                <StepOne
                  classes={classes}
                  setName={setName}
                  Name={Name}
                  weight={weight}
                  setWeight={setWeight}
                  setPrice={setPrice}
                  Price={Price}
                  AllCategory={AllCategory}
                  setCategory={setCategory}
                  Category={Category}
                  setSubCategory={setSubCategory}
                  SubCategory={SubCategory}
                  AllSubCategoriesByCategory={AllSubCategoriesByCategory}
                  Tags={Tags}
                  TagsData={TagsData}
                  handleChangeTagsSelect={handleChangeTagsSelect}
                  size={size}
                  sizeSelected={sizeSelected}
                  handleChangeSelect={handleChangeSelect}
                  productId={productId}
                  setDescription={setDescription}
                  DescriptionnonEditable={DescriptionnonEditable}
                  Description={Description}
                  addProductDetail={addProductDetail}
                  handleChangeColorSelect={handleChangeColorSelect}
                  colors={colors}
                  colorSelected={colorSelected}
                  loading={loading}
                  setPieces={setPieces}
                  pieces={pieces}
                />
              ) : current === 1 ? (
                <StepTwo
                  itemsVariety={itemsVariety}
                  setItemsVariety={setItemsVariety}
                  classes={classes}
                  productId={productId}
                  addProductVarient={addProductVarient}
                  loading={loading}
                />
              ) : current === 2 ? (
                <StepThree
                  classes={classes}
                  productId={productId}
                  colorSelected={colorSelected}
                  value={value}
                  handleChangeTab={handleChangeTab}
                  setSelectedColor={setSelectedColor}
                  setTabColor={setTabColor}
                  TabColor={TabColor}
                  imageUpload={imageUpload}
                  setImageUpload={setImageUpload}
                  madeImage={madeImage}
                  SelectedColor={SelectedColor}
                  fileTypes={fileTypes}
                  loading={loading}
                  addProductImages={addProductImages}
                />
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default withAppContext(CreateProduct);

// `UPDATE Products SET name='${Product.name}', price=${Product.price
// } ,weight=${Product.weight} , Description='${Product.Description}',	Category='${Product.Category
// }' ,	SubCategory='${Product.SubCategory}' , piece='${
//   Product.piece
// }',	Tags='${typeof Product?.Tags === "string"
//   ? Product?.Tags
//   : Product?.Tags?.join() || ""
// }' ,	Color='${Product.Color}' , size='${Product.size}' , Discount=${Product.Discount
// }  WHERE id= ?`
